const ValidLanguages = {
  'aa': 'aa', 'ab': 'ab', 'ae': 'ae', 'af': 'af', 'ak': 'ak', 'am': 'am',
  'an': 'an', 'ar': 'ar', 'as': 'as', 'av': 'av', 'ay': 'ay', 'az': 'az',
  'ba': 'ba', 'be': 'be', 'bg': 'bg', 'bh': 'bh', 'bi': 'bi', 'bm': 'bm',
  'bn': 'bn', 'bo': 'bo', 'br': 'br', 'bs': 'bs', 'ca': 'ca', 'ce': 'ce',
  'ch': 'ch', 'co': 'co', 'cr': 'cr', 'cs': 'cs', 'cu': 'cu', 'cv': 'cv',
  'cy': 'cy', 'da': 'da', 'de': 'de', 'dv': 'dv', 'dz': 'dz', 'ee': 'ee',
  'el': 'el', 'en': 'en', 'eo': 'eo', 'es': 'es', 'et': 'et', 'eu': 'eu',
  'fa': 'fa', 'ff': 'ff', 'fi': 'fi', 'fj': 'fj', 'fo': 'fo', 'fr': 'fr',
  'fy': 'fy', 'ga': 'ga', 'gd': 'gd', 'gl': 'gl', 'gn': 'gn', 'gu': 'gu',
  'gv': 'gv', 'ha': 'ha', 'he': 'he', 'hi': 'hi', 'ho': 'ho', 'hr': 'hr',
  'ht': 'ht', 'hu': 'hu', 'hy': 'hy', 'hz': 'hz', 'ia': 'ia', 'id': 'id',
  'ie': 'ie', 'ig': 'ig', 'ii': 'ii', 'ik': 'ik', 'io': 'io', 'is': 'is',
  'it': 'it', 'iu': 'iu', 'ja': 'ja', 'jv': 'jv', 'ka': 'ka', 'kg': 'kg',
  'ki': 'ki', 'kj': 'kj', 'kk': 'kk', 'kl': 'kl', 'km': 'km', 'kn': 'kn',
  'ko': 'ko', 'kr': 'kr', 'ks': 'ks', 'ku': 'ku', 'kv': 'kv', 'kw': 'kw',
  'ky': 'ky', 'la': 'la', 'lb': 'lb', 'lg': 'lg', 'li': 'li', 'ln': 'ln',
  'lo': 'lo', 'lt': 'lt', 'lu': 'lu', 'lv': 'lv', 'mg': 'mg', 'mh': 'mh',
  'mi': 'mi', 'mk': 'mk', 'ml': 'ml', 'mn': 'mn', 'mo': 'mo', 'mr': 'mr',
  'ms': 'ms', 'mt': 'mt', 'my': 'my', 'na': 'na', 'nb': 'nb', 'nd': 'nd',
  'ne': 'ne', 'ng': 'ng', 'nl': 'nl', 'nn': 'nn', 'no': 'no', 'nr': 'nr',
  'nv': 'nv', 'ny': 'ny', 'oc': 'oc', 'oj': 'oj', 'om': 'om', 'or': 'or',
  'os': 'os', 'pa': 'pa', 'pi': 'pi', 'pl': 'pl', 'ps': 'ps', 'pt': 'pt',
  'qu': 'qu', 'rm': 'rm', 'rn': 'rn', 'ro': 'ro', 'ru': 'ru', 'rw': 'rw',
  'sa': 'sa', 'sc': 'sc', 'sd': 'sd', 'se': 'se', 'sg': 'sg', 'sh': 'sh',
  'si': 'si', 'sk': 'sk', 'sl': 'sl', 'sm': 'sm', 'sn': 'sn', 'so': 'so',
  'sq': 'sq', 'sr': 'sr', 'ss': 'ss', 'st': 'st', 'su': 'su', 'sv': 'sv',
  'sw': 'sw', 'ta': 'ta', 'te': 'te', 'tg': 'tg', 'th': 'th', 'ti': 'ti',
  'tk': 'tk', 'tl': 'tl', 'tn': 'tn', 'to': 'to', 'tr': 'tr', 'ts': 'ts',
  'tt': 'tt', 'tw': 'tw', 'ty': 'ty', 'ug': 'ug', 'uk': 'uk', 'ur': 'ur',
  'uz': 'uz', 've': 've', 'vi': 'vi', 'vo': 'vo', 'wa': 'wa', 'wo': 'wo',
  'xh': 'xh', 'yi': 'yi', 'yo': 'yo', 'za': 'za', 'zh': 'zh', 'zu': 'zu',
  'aar': 'aar', 'abk': 'abk', 'ave': 'ave', 'afr': 'afr', 'aka': 'aka',
  'amh': 'amh', 'arg': 'arg', 'ara': 'ara', 'asm': 'asm', 'ava': 'ava',
  'aym': 'aym', 'aze': 'aze', 'bak': 'bak', 'bel': 'bel', 'bul': 'bul',
  'bih': 'bih', 'bis': 'bis', 'bam': 'bam', 'ben': 'ben', 'tib': 'tib',
  'bod': 'bod', 'bre': 'bre', 'bos': 'bos', 'cat': 'cat', 'che': 'che',
  'cha': 'cha', 'cos': 'cos', 'cre': 'cre', 'cze': 'cze', 'ces': 'ces',
  'chu': 'chu', 'chv': 'chv', 'wel': 'wel', 'cym': 'cym', 'dan': 'dan',
  'ger': 'ger', 'deu': 'deu', 'div': 'div', 'dzo': 'dzo', 'ewe': 'ewe',
  'gre': 'gre', 'ell': 'ell', 'eng': 'eng', 'epo': 'epo', 'spa': 'spa',
  'est': 'est', 'baq': 'baq', 'eus': 'eus', 'per': 'per', 'fas': 'fas',
  'ful': 'ful', 'fin': 'fin', 'fij': 'fij', 'fao': 'fao', 'fre': 'fre',
  'fra': 'fra', 'fry': 'fry', 'gle': 'gle', 'gla': 'gla', 'glg': 'glg',
  'grn': 'grn', 'guj': 'guj', 'glv': 'glv', 'hau': 'hau', 'heb': 'heb',
  'hin': 'hin', 'hmo': 'hmo', 'hrv': 'hrv', 'hat': 'hat', 'hun': 'hun',
  'arm': 'arm', 'hye': 'hye', 'her': 'her', 'ina': 'ina', 'ind': 'ind',
  'ile': 'ile', 'ibo': 'ibo', 'iii': 'iii', 'ipk': 'ipk', 'ido': 'ido',
  'ice': 'ice', 'isl': 'isl', 'ita': 'ita', 'iku': 'iku', 'jpn': 'jpn',
  'jav': 'jav', 'geo': 'geo', 'kat': 'kat', 'kon': 'kon', 'kik': 'kik',
  'kua': 'kua', 'kaz': 'kaz', 'kal': 'kal', 'khm': 'khm', 'kan': 'kan',
  'kor': 'kor', 'kau': 'kau', 'kas': 'kas', 'kur': 'kur', 'kom': 'kom',
  'cor': 'cor', 'kir': 'kir', 'lat': 'lat', 'ltz': 'ltz', 'lug': 'lug',
  'lim': 'lim', 'lin': 'lin', 'lao': 'lao', 'lit': 'lit', 'lub': 'lub',
  'lav': 'lav', 'mlg': 'mlg', 'mah': 'mah', 'mao': 'mao', 'mri': 'mri',
  'mac': 'mac', 'mkd': 'mkd', 'mal': 'mal', 'mon': 'mon', 'mol': 'mol',
  'mar': 'mar', 'may': 'may', 'msa': 'msa', 'mlt': 'mlt', 'bur': 'bur',
  'mya': 'mya', 'nau': 'nau', 'nob': 'nob', 'nde': 'nde', 'nep': 'nep',
  'ndo': 'ndo', 'dut': 'dut', 'nld': 'nld', 'nno': 'nno', 'nor': 'nor',
  'nbl': 'nbl', 'nav': 'nav', 'nya': 'nya', 'oci': 'oci', 'oji': 'oji',
  'orm': 'orm', 'ori': 'ori', 'oss': 'oss', 'pan': 'pan', 'pli': 'pli',
  'pol': 'pol', 'pus': 'pus', 'por': 'por', 'que': 'que', 'roh': 'roh',
  'run': 'run', 'rum': 'rum', 'ron': 'ron', 'rus': 'rus', 'kin': 'kin',
  'san': 'san', 'srd': 'srd', 'snd': 'snd', 'sme': 'sme', 'sag': 'sag',
  'slo': 'slo', 'sin': 'sin', 'slk': 'slk', 'slv': 'slv', 'smo': 'smo',
  'sna': 'sna', 'som': 'som', 'alb': 'alb', 'sqi': 'sqi', 'srp': 'srp',
  'ssw': 'ssw', 'sot': 'sot', 'sun': 'sun', 'swe': 'swe', 'swa': 'swa',
  'tam': 'tam', 'tel': 'tel', 'tgk': 'tgk', 'tha': 'tha', 'tir': 'tir',
  'tuk': 'tuk', 'tgl': 'tgl', 'tsn': 'tsn', 'ton': 'ton', 'tur': 'tur',
  'tso': 'tso', 'tat': 'tat', 'twi': 'twi', 'tah': 'tah', 'uig': 'uig',
  'ukr': 'ukr', 'urd': 'urd', 'uzb': 'uzb', 'ven': 'ven', 'vie': 'vie',
  'vol': 'vol', 'wln': 'wln', 'wol': 'wol', 'xho': 'xho', 'yid': 'yid',
  'yor': 'yor', 'zha': 'zha', 'chi': 'chi', 'zho': 'zho', 'zul': 'zul',
};

export default ValidLanguages;
